import React from "react"
import Footer from "../components/Footer"
import HeroNav from "../components/HeroNav"
import FacebookButton from "./FacebookButton"
import SEO from "../components/SEO"
import "../css/styles.scss"

const Layout = ({
  title,
  secondTitle,
  children,
  fluid,
  alt,
  seoTitle,
  seoDescription,
  heroSize,
}) => {
  return (
    <div className="has-background-white-bis">
      <SEO siteTitle={seoTitle} siteDescription={seoDescription} />
      <HeroNav
        title={title}
        secondtitle={secondTitle}
        fluid={fluid}
        alt={alt}
        heroSize={heroSize}
      />
      <main className="has-background-primary-light">{children}</main>
      <FacebookButton />
      <Footer />
    </div>
  )
}

export default Layout
