import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getSiteMetadata = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        facebookUsername
        themeColor
      }
    }
  }
`

const SEO = ({ siteTitle, siteDescription }) => {
  const { site } = useStaticQuery(getSiteMetadata)
  const {
    title,
    description,
    author,
    siteUrl,
    facebookUsername,
    themeColor,
  } = site.siteMetadata

  return (
    <Helmet htmlAttributes={{ lang: "de" }} title={`${title || siteTitle}`}>
      <meta name="description" content={description || siteDescription} />
      <meta name="author" content={author} />
      <meta name="siteUrl" content={siteUrl} />
      <meta name="facebookUsername" content={facebookUsername} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  )
}

export default SEO
