import React from "react"
import Logo from "../images/SW-blau.svg"
import { Link } from "gatsby"
import FollowUs from "./FollowUs"
import { AiOutlineMail } from "react-icons/ai"
import { AiOutlinePushpin } from "react-icons/ai"

const Footer = () => {
  return (
    <div>
      <FollowUs />
      <footer className="footer has-background-primary-light" id="kontakt">
        <div className="container is-size-5">
          <div className="columns">
            <div className="column">
              <h4 className="title is-4">WirtschaftsPlattForm Schwechat</h4>
              <Link to="/" className="is-link">
                <img src={Logo} alt="Logo" className="logoFooter" />
              </Link>
            </div>
            <div className="column">
              <h4 className="title is-4">Kontakt</h4>
              <AiOutlinePushpin className="icon is-medium has-text-warning" />
              <p>Schmidgasse 6, 2320 Schwechat</p>
              <AiOutlineMail className="icon is-medium has-text-warning" />
              <p>
                <a
                  href="mailto:info@WirtschaftsPlattForm.at"
                  className="is-link"
                >
                  info@WirtschaftsPlattForm.at
                </a>
              </p>
            </div>
            <div className="column">
              <h4 className="title is-4">Links</h4>
              <p className="pb-1">
                <Link to="/veranstaltungen" className="is-link">
                  Veranstaltungen
                </Link>
              </p>
              <p>
                <Link to="/betriebe" className="is-link">
                  Betriebe
                </Link>
              </p>
              <p className="pb-1">
                <Link to="/impressum" className="is-link">
                  Impressum
                </Link>
              </p>
              <p>
                <Link to="/datenschutz" className="is-link">
                  Datenschutz
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
