import React from "react"
import { AiFillFacebook } from "react-icons/ai"

const FollowUs = () => {
  return (
    <section className="section is-small has-background-primary followUs">
      <div
        className="container has-text-centered "
        data-sal="fade"
        data-sal-delay="300"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <h3 className="title is-3 has-text-white">Folge Uns</h3>
        <a
          href="https://www.facebook.com/Schwechater.Wirtschaftsplattform/"
          aria-label="WirtschaftsPlattForm Schwechat Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillFacebook className="icon is-large has-text-white" />
        </a>
      </div>
    </section>
  )
}

export default FollowUs
