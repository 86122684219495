import React from "react"
import { Link } from "gatsby"
import Logo from "../images/SW-blau.svg"
import BackgroundImage from "gatsby-background-image"

const NavbarBurger = props => (
  <span
    onClick={props.toggleMenu}
    onKeyDown={props.toggleMenu}
    role="button"
    tabIndex="0"
    aria-label="burgerMenu"
    className={`navbar-burger burger has-text-white ${
      props.active ? "is-active" : ""
    }`}
  >
    <span />
    <span />
    <span />
  </span>
)

export default class HeroNav extends React.Component {
  state = {
    activeMenu: false,
  }

  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    })
  }

  render() {
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="kontakt"]')
    }
    return (
      <section className="hero is-large is-primary">
        <div className="hero-head">
          <nav className="navbar has-background-white-ter">
            <div className="container">
              <div className="navbar-brand ">
                <Link to="/" className="navbar-item">
                  <img src={Logo} alt="Logo" className="logo" />
                </Link>
                <NavbarBurger
                  active={this.state.activeMenu}
                  toggleMenu={this.toggleMenu}
                />
              </div>
              <div
                className={`navbar-menu  ${
                  this.state.activeMenu ? "is-active" : ""
                }`}
              >
                <div className="navbar-end has-text-centered">
                  <Link
                    to="/"
                    activeClassName="is-active"
                    className="is-size-5 navbar-item has-text-left has-text-white"
                  >
                    Aktuelles
                  </Link>
                  <Link
                    to="/veranstaltungen/"
                    activeClassName="is-active"
                    className="is-size-5 navbar-item has-text-left has-text-white"
                  >
                    Veranstaltungen
                  </Link>
                  <Link
                    activeClassName="is-active"
                    to="/betriebe"
                    className="is-size-5 navbar-item has-text-left has-text-white"
                  >
                    Betriebe
                  </Link>
                  <Link
                    to="/#kontakt"
                    className="is-size-5 navbar-item has-text-left has-text-white"
                  >
                    Kontakt
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        {this.props.fluid ? (
          <BackgroundImage fluid={this.props.fluid} alt={this.props.alt}>
            <div className="heroLayer">
              <div className={`hero-body ${this.props.heroSize}`}>
                <div
                  className="container has-text-centered"
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <h1 className="title is-1 text-shadow has-text-white">
                    {this.props.title}
                  </h1>
                  <h2 className="subtitle is-3 text-shadow has-text-white is-italic">
                    {this.props.secondtitle}
                  </h2>
                </div>
              </div>
            </div>
          </BackgroundImage>
        ) : (
          <div className="hero-body small has-background-primary">
            <div
              className="container"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <h1 className="title is-3 text-shadow has-text-white ">
                {this.props.title}
              </h1>

              <h2 className="subtitle is-4 text-shadow has-text-white is-italic">
                {this.props.secondtitle}
              </h2>
            </div>
          </div>
        )}
      </section>
    )
  }
}
