import React, { Component } from "react"
import { GrFacebook } from "react-icons/gr"

class Index extends Component {
  state = {
    opacity: "0",
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset
        let maxScroll = document.body.scrollHeight - window.innerHeight
        // console.log(maxScroll)
        if (currentScrollPos > 200 && currentScrollPos < maxScroll - 400) {
          this.setState({ opacity: "1" })
          // console.log(currentScrollPos)
        } else {
          this.setState({ opacity: "0" })
        }
      }
    }
  }

  render() {
    return (
      <div className="facebook" style={{ opacity: `${this.state.opacity}` }}>
        <a
          href="https://www.facebook.com/Schwechater.Wirtschaftsplattform/"
          className="facebookDiv is-hidden-mobile"
          aria-label="Wirtschaftsplattform Schwechat Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GrFacebook className="facebookBtn  has-text-white mx-1 ml-3" />
          <div className="has-text-centered">
            <span className="is-size-5 has-text-white">
              Folge Uns auf Facebook
            </span>
          </div>
        </a>
        <a
          href="https://www.facebook.com/Schwechater.Wirtschaftsplattform/"
          className="facebookDivMobile is-hidden-tablet"
          aria-label="Wirtschaftsplattform Schwechat Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GrFacebook className="facebookBtn mx-1 ml-3 has-background-white" />
        </a>
      </div>
    )
  }
}

export default Index
